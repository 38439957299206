@use 'global/mixin' as m;
@use 'global/variable' as v;

.p-history {
  background-color: #ededed;
  padding: 0 0 17rem;
  @include m.sp {
    padding: 0 0 9rem;
  }
  &_background {
    // background-image: url('../img/history_background_01.png');
    // background-repeat: no-repeat;
    // background-size: auto 100%;
    // width: 100%;
    // background-position: right;
    // height: 47rem;
    max-width: 110.9rem;
    margin-left: auto;
    width: calc(100% - 20px);
    @include m.sp {
      width: 90%;
    }
  }
  &_frame {
    border: solid 3px #6dba44;
    background-color: #fff;
    padding: 10rem 3%;
    margin: 6rem 0 0;
    @include m.sp {
      padding: 5rem 3%;
      margin: 3rem 0 0;
    }
  }
  &_container {
    display: flex;
    position: relative;
    padding: 0 0 4rem;
    @include m.sp {
      display: block;
      padding: 0 0 2rem;
    }
    &--left {
      &::after {
        content: '';
        position: absolute;
        top: 15px;
        left: 50%;
        // width: 2px;
        height: 100%;
        border-left: 2px solid #6dba44;
        // background-color: #6dba44;
        @include m.sp {
          top: 20px;
          left: 0;
        }
      }
      &--dashed {
        &::after {
          top: 0;
          height: calc(100% + 15px);
          border-left: 2px dashed #6dba44;
        }
      }
    }
    &--right {
      justify-content: flex-end;
      &::after {
        content: '';
        position: absolute;
        top: 15px;
        left: 50%;
        // width: 2px;
        height: 100%;
        border-right: 2px solid #6dba44;
        // background-color: #6dba44;
        @include m.sp {
          top: 20px;
          left: 0;
        }
      }
      &--dashed {
        &::after {
          top: 0;
          height: calc(100% + 15px);
          border-right: 2px dashed #6dba44;
        }
      }
    }
  }
  &_contents {
    display: flex;
    @include m.sp {
      display: block;
    }
    &--left {
      padding: 0 4rem 0 0;
      justify-content: flex-end;
      @include m.sp {
        padding: 0 0 0 2rem;
      }
      &--start {
        padding: 0 4rem 0 0;
        justify-content: flex-start;
        @include m.sp {
          padding: 0 0 0 2rem;
        }
      }
    }
    &--right {
      padding: 0 0 0 4rem;
      @include m.sp {
        padding: 0 0 0 2rem;
      }
    }
  }
  &_number {
    max-width: 20.6rem;
    width: 100%;
    @include m.sp {
      max-width: 8rem;
    }
  }
  &_text {
    @include m.pc {
      margin: 0 0 0 1rem;
    }
  }
  &_illust {
    position: absolute;
    width: 100%;
    &--01 {
      max-width: 28rem;
      left: 14%;
      top: 1%;
      width: 28%;
      @include m.sp {
        left: 50%;
        width: 38%;
      }
    }
    &--02 {
      max-width: 27rem;
      left: 55%;
      top: 32%;
      width: 27%;
      @include m.sp {
        left: 55%;
        top: 29.5%;
        width: 37%;
      }
    }
    &--03 {
      max-width: 28rem;
      left: 14%;
      top: 64%;
      width: 28%;
      @include m.sp {
        left: 50%;
        top: 63%;
        width: 38%;
      }
    }
    &--04 {
      max-width: 33rem;
      left: 2%;
      top: 98.5%;
      width: 33%;
      @include m.sp {
        top: 99%;
        width: 43%;
      }
    }
  }
  &_item {
    flex-basis: 50%;
    position: relative;
    &--left {
      &::before {
        content: '';
        position: absolute;
        width: 12px;
        height: 12px;
        background-color: #6dba44;
        border-radius: 50%;
        right: -7px;
        top: 8px;
        @include m.sp {
          right: initial;
          left: -5px;
          top: 0.55rem;
        }
      }
      &::after {
        content: '';
        position: absolute;
        top: 13px;
        right: -2px;
        width: 3rem;
        border-top: #6dba44 dashed 2px;
        @include m.sp {
          right: initial;
          left: -2px;
          width: 2rem;
          top: 1rem;
        }
      }
    }
    &--right {
      &::before {
        content: '';
        position: absolute;
        width: 12px;
        height: 12px;
        background-color: #6dba44;
        border-radius: 50%;
        left: -5px;
        top: 8px;
        @include m.sp {
          top: 0.55rem;
        }
      }
      &::after {
        content: '';
        position: absolute;
        top: 13px;
        left: -2px;
        width: 3rem;
        border-top: #6dba44 dashed 2px;
        @include m.sp {
          width: 2rem;
          top: 1rem;
        }
      }
    }
    &--number {
    }
  }
}

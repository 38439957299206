@use 'global/mixin' as m;
@use 'global/variable' as v;

.l-footer {
  background-color: #6dba44;
  padding: 10rem 0 6rem;
  @include m.sp {
    padding: 4rem 0 2rem;
  }
  &_logo {
    max-width: 22rem;
    padding: 1rem;
    width: 100%;
    background-color: #fff;
    border-radius: 1rem;
    @include m.sp {
      max-width: 11rem;
    }
  }
  &_copyright {
    font-size: 1.2rem;
    color: #fff;
    letter-spacing: 0.06em;
    text-align: right;
    margin: 5rem 0 0 0;
    @include m.sp {
      margin: 2.5rem 0 0 0;
      text-align: center;
    }
  }
  &_container {
    display: flex;
    justify-content: space-between;
    @include m.sp {
      display: block;
      width: 90%;
      margin: 0 auto;
    }
  }
  &_title {
    font-size: 1.8rem;
    font-weight: bold;
    font-family: v.$zenMaruGothic;
    letter-spacing: 0.06em;
    color: #fff;
    margin: 3rem 0 0;
    @include m.sp {
      margin: 1.5rem 0 0;
      font-size: 1.4rem;
    }
  }
  &_info {
    font-size: 1.6rem;
    font-family: v.$zenMaruGothic;
    letter-spacing: 0.06em;
    color: #fff;
    @include m.sp {
      font-size: 1.2rem;
    }
  }
}

@use 'global/mixin' as m;
@use 'global/variable' as v;

.c-hamburger {
  &_shape {
    position: fixed;
    background-color: #6dba44;
    width: 18rem;
    height: 18rem;
    border-radius: 50%;
    top: -8rem;
    right: -8rem;
    z-index: 99;
    @include m.sp {
      top: -11rem;
      right: -11rem;
    }
  }
  &_box {
    position: fixed;
    width: 2.6rem;
    height: 2.2rem;
    right: 3rem;
    top: 3rem;
    cursor: pointer;
    @include m.pc {
      transition: transform 0.3s ease;
      &:hover {
        transform: scale(1.2);
      }
    }
    @include m.sp {
      right: 1.5rem;
      top: 1.5rem;
    }
  }
  &_line {
    width: 100%;
    height: 0.2rem;
    background-color: #fff;
    position: absolute;
    &:nth-child(1) {
      top: 0;
      transition: transform 0.3s ease;
    }
    &:nth-child(2) {
      top: 1rem;
      transition: opacity 0.3s ease;
    }
    &:nth-child(3) {
      bottom: 0;
      transition: transform 0.3s ease;
    }
  }
  &_wrap {
    background-color: #6dba44;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    opacity: 0;
    padding: 0;
    height: 0;
    visibility: hidden;
    transition: opacity 0.3s ease, height 0.3s ease;
    &.is-open {
      padding: 12rem 0;
      opacity: 1;
      visibility: visible;
      height: 800px;
      @include m.hamburgerHeight {
        height: 100vh;
        overflow-y: scroll;
      }
      @include m.sp {
        padding: 6rem 0;
        height: 100vh;
        overflow-y: scroll;
      }
    }
  }
  &_card {
    background-color: #fff;
    border-radius: 1rem;
    padding: 3.5rem 3rem;
    margin: 6rem 0 0;
    @include m.sp {
      margin: 3rem 0 0;
      padding: 1.5rem;
    }
  }
  &_phone {
    @include m.wrap {
      font-size: 3.4rem !important;
    }
  }
}

.c-hamburger_box.is-open > .c-hamburger_line {
  &:nth-child(1) {
    transform: translateY(1rem) rotate(-45deg);
  }
  &:nth-child(2) {
    opacity: 0;
  }
  &:nth-child(3) {
    transform: translateY(-1rem) rotate(45deg);
  }
}

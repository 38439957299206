@use 'global/mixin' as m;
@use 'global/variable' as v;

.p-news {
  &_container {
    display: flex;
    align-items: center;
    padding: 1.5rem 2rem;
    @include m.sp {
      padding: 1rem;
    }
  }
  &_illust {
    position: absolute;
    top: -6rem;
    left: 85%;
    transform: translateX(-50%);
    @include m.sp {
      top: -10vw;
      left: 70%;
    }
  }
  &_position {
    position: relative;
    padding: 0 0 12rem 0;
    @include m.sp {
      padding: 6rem 0;
    }
  }
  &_wrap {
    max-width: 80rem;
    width: 100%;
    margin: 0 auto;
  }
  &_list {
    border-top: #dcdcdc solid 1px;
    &:last-child {
      border-bottom: #dcdcdc solid 1px;
    }
  }
  &_link {
    font-size: 1.4rem;
    font-weight: 500;
    letter-spacing: 0.06em;
    line-height: calc(17 / 14);
    margin: 0 0 0 2rem;
    flex: 1;
    @include m.pc {
      transition: color 0.3s ease;
      &:hover {
        color: #6dba44;
      }
    }
    @include m.sp {
      font-size: 1.2rem;
      margin: 0 0 0 1rem;
    }
  }
  &_button {
    margin: 10rem auto 0;
    @include m.sp {
      margin: 5rem auto 0;
    }
  }
  &_card {
    background-color: #fff;
    border-radius: 1rem;
    @include m.pc {
      padding: 12rem 10rem;
      border: solid 1px #ededed;
    }
  }
  &_badge {
    display: block;
    border-radius: 10rem;
    max-width: 12rem;
    width: 100%;
    text-align: center;
    color: #fff;
    padding: 0.6rem;
    font-size: 1.4rem;
    font-family: v.$zenMaruGothic;
    font-weight: 500;
    @include m.sp {
      max-width: 10rem;
      font-size: 1.2rem;
    }
    &--yellow {
      background-color: #f8b500;
    }
    &--green {
      background-color: #6dba44;
    }
    &--red {
      background-color: #bd1e48;
    }
  }
  &_detail {
    h1 {
      font-size: 2.2rem;
      letter-spacing: 0.06em;
      line-height: 2;
      font-weight: 500;
    }
    h2 {
      font-size: 2rem;
      letter-spacing: 0.06em;
      line-height: 2;
      font-weight: 500;
    }
    h3 {
      font-size: 1.8rem;
      letter-spacing: 0.06em;
      line-height: 2;
      font-weight: 500;
    }
    h4 {
      font-size: 1.6rem;
      letter-spacing: 0.06em;
      line-height: 2;
      font-weight: 500;
    }
    h5 {
      font-size: 1.4rem;
      letter-spacing: 0.06em;
      line-height: 2;
      font-weight: 500;
    }
    h6 {
      font-size: 1.2rem;
      letter-spacing: 0.06em;
      line-height: 2;
      font-weight: 500;
    }
    p {
      font-size: 1.6rem;
      letter-spacing: 0.06em;
      line-height: 2;
      font-weight: 500;
    }
    a {
      font-size: 1.6rem;
      letter-spacing: 0.06em;
      line-height: 2;
      font-weight: 500;
      text-decoration: underline;
    }
    img {
      width: auto;
      max-width: 100%;
    }
  }
}

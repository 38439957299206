.u-script {
  &_sup {
    vertical-align: super;
    font-size: smaller;
  }
  &_sub {
    vertical-align: sub;
    font-size: smaller;
  }
}

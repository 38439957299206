@use 'global/mixin' as m;
@use 'global/variable' as v;

.c-container {
  display: flex;
  @include m.sp {
    display: block;
  }
  &_keep {
    display: flex;
  }
  &_columnCenter {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  &--center {
    justify-content: center;
  }
  &--between {
    justify-content: space-between;
  }
  &--itemCenter {
    align-items: center;
  }
  &--itemFlexEnd {
    align-items: flex-end;
  }
  &--rowReverse {
    flex-direction: row-reverse;
  }
  &--contact {
    align-items: center;
    max-width: 81rem;
    width: 100%;
    margin: 0 auto;
    &:first-of-type {
      margin: 6rem auto 0;
      @include m.sp {
        margin: 3rem auto 0;
      }
    }
    @include m.sp {
      & + & {
        margin: 2rem auto 0;
      }
    }
  }
  &_list {
    width: 100%;
    @include m.sp {
      & + & {
        margin: 2rem auto 0;
      }
    }
  }
  &_diagonal {
    flex-basis: 30%;
    &:nth-child(2) {
      margin: 3rem 0rem 0 0rem;
      @include m.sp {
        margin: 6rem 0rem 0 0rem;
      }
    }
    &:nth-child(3) {
      margin: 6rem 0rem 0 0rem;
      @include m.sp {
        margin: 6rem 0rem 0 0rem;
      }
    }
  }
  &_right {
    margin: 0 0 0 4rem;
    @include m.sp {
      margin: 2rem 0 0;
    }
    &--near {
      margin: 0 0 0 2rem;
      @include m.sp {
        margin: 2rem 0 0;
      }
    }
  }
  &_item {
    &--trisect {
      flex-basis: 30%;
    }
    &--hub {
      flex-basis: 47.5%;
    }
    @include m.pc {
      transition: transform 0.4s ease;
      &:hover {
        transform: scale(1.05);
      }
    }
    @include m.sp {
      width: 90%;
      margin: 0 auto;
      & + & {
        margin: 2rem auto 0;
      }
    }
  }
}

@use 'global/mixin' as m;
@use 'global/variable' as v;

.c-content {
  &_wrap {
    max-width: 1400px;
    position: relative;
    width: 100%;
    margin: 0 auto;
  }
  &_container {
    @include m.content {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    &--reverse {
      display: flex;
      justify-content: flex-end;
      @include m.content {
        flex-direction: row-reverse;
      }
      @include m.sp {
        display: block;
      }
    }
  }
  &_item {
    width: 100%;
    max-width: 39rem;
    &--work {
      @include m.nearContent {
        max-width: 30rem;
      }
      @include m.content {
        max-width: 39vw;
      }
    }
  }
  &Image {
    &_right {
      &--work {
        max-width: 74rem;
        width: 100%;
        @include m.overContent {
          position: absolute;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
        }
        @include m.content {
          width: 52%;
        }
        @include m.sp {
          margin: 2rem 0 0 0;
        }
      }
      &--print {
        max-width: 74rem;
        width: 100%;
        @include m.overContent {
          position: absolute;
          right: 0;
          bottom: 0;
        }
        @include m.content {
          width: 52%;
        }
        @include m.sp {
          margin: 2rem 0 0 0;
        }
      }
      &--eco {
        max-width: 74rem;
        width: 100%;
        @include m.overContent {
          position: absolute;
          right: 0;
          top: 0;
        }
        @include m.content {
          width: 52%;
        }
        @include m.sp {
          margin: 2rem 0 0 0;
        }
      }
      &--rinen {
        max-width: 70rem;
        width: 100%;
        @include m.overContent {
          position: absolute;
          right: 0;
          bottom: -2rem;
        }
        @include m.content {
          padding: 0 0 8rem 0;
          flex: 1;
          margin: 0 0 0 2rem;
        }
        @include m.sp {
          margin: 2rem 0 0 0;
        }
      }
    }
    &_left {
      &--rinen {
        max-width: 70rem;
        width: 100%;
        @include m.overContent {
          position: absolute;
          left: 0;
          bottom: -8rem;
        }
        @include m.content {
          padding: 0 0 8rem 0;
          flex: 1;
          margin: 0 2rem 0 0;
        }
        @include m.sp {
          margin: 2rem 0 0 0;
        }
      }
    }
  }
}

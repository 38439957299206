@use 'global/mixin' as m;
@use 'global/variable' as v;

.c-band {
  background-color: #6dba44;
  border-top-right-radius: 1rem;
  border-bottom-right-radius: 1rem;
  left: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  padding: 5rem 5rem 6rem 9rem;
  @include m.sp {
    width: fit-content;
    top: 90%;
    padding: 6vw;
  }
  &--blue {
    background-color: #005bac;
  }
  &--narrow {
    padding: 4rem calc(5rem + 3.4rem) 4rem 9rem;
    @include m.sp {
      top: 80%;
      padding: 6vw 10.4vw 6vw 6vw;
    }
  }
  &--sub {
    top: 75%;
    @include m.sp {
      top: 80%;
    }
  }
  &--80 {
    top: 80%;
    @include m.sp {
      top: 80%;
    }
  }
  &--85 {
    top: 85%;
    @include m.sp {
      top: 80%;
    }
  }
  &--90 {
    top: 90%;
    @include m.sp {
      top: 80%;
    }
  }
  &--95 {
    top: 95%;
    @include m.sp {
      top: 80%;
    }
  }
  &--short {
    @include m.pc {
      max-width: 46rem;
      width: 100%;
    }
  }
  &--news {
    top: 27%;
    padding: 3rem 6rem 3rem 17%;
    @include m.sp {
      top: 80%;
      padding: 6vw;
    }
  }
  &_en {
    font-size: 1.4rem;
    font-weight: 900;
    letter-spacing: 0.06em;
    font-family: v.$zenMaruGothic;
    color: #fff;
    position: relative;
    display: inline-block;
    @include m.sp {
      font-size: 2.4vw;
    }
    &::after {
      content: '';
      position: absolute;
      width: 3.4rem;
      height: 2px;
      background-color: #fff;
      top: 50%;
      right: -4rem;
      transform: translateY(-50%);
      @include m.sp {
        width: 4.4vw;
        right: -2rem;
      }
    }
    &--sub {
      @include m.sp {
        font-size: 3vw;
      }
    }
  }
  &_title {
    font-size: 3.4rem;
    letter-spacing: 0.06em;
    font-family: v.$zenMaruGothic;
    color: #fff;
    margin: 3rem 0 0;
    @include m.sp {
      margin: 3vw 0 0;
      font-size: 4.4vw;
    }
    &--sub {
      @include m.sp {
        margin: 3vw 0 0;
        font-size: 6vw;
      }
    }
    &--news {
      margin: 0;
      text-align: center;
      font-size: 4.2rem;
      @include m.sp {
        font-size: 6vw;
      }
    }
    &--small {
      margin: 0;
      font-size: 1.8rem;

      @include m.sp {
        font-size: 2.8vw;
      }
    }
  }
  &_text {
    font-size: 1.8rem;
    letter-spacing: 0.06em;
    font-family: v.$zenMaruGothic;
    color: #fff;
    margin: 3rem 0 0;
    @include m.sp {
      margin: 3vw 0 0;
      font-size: 2.8vw;
    }
    &--sub {
      @include m.sp {
        margin: 3vw 0 0;
        font-size: 4vw;
      }
    }
    &--small {
      font-size: 1.4rem;
      margin: 0;
      text-align: center;
      @include m.sp {
        font-size: 4vw;
      }
    }
  }
  &_button {
    margin: 3rem 0 0;
    text-align: left !important;
    width: 100%;
    padding: 0.8rem 0.8rem 0.8rem 2rem;
    display: flex !important;
    align-items: center;

    @include m.pc {
      max-width: 32rem;
    }

    @include m.sp {
      padding: 1.4vw 2vw 1.4vw 4vw;
      font-size: 3.2vw !important;
      margin: 3vw 0 0;
    }
  }
  &_arrow {
    display: block;
    width: 100%;
    max-width: 2.6rem;
    margin: 0.4rem 0 0 1rem;

    @include m.sp {
      max-width: 1.8rem;
      margin: 0 0 0 0.8rem;
    }
  }
}

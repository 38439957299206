@use 'global/mixin' as m;
@use 'global/variable' as v;

html {
  font-size: 10px;
  font-family: v.$yuGothic;
  @include m.sp {
    font-size: calc(100vw / 32);
  }
}

body {
  border: 10px solid #6dba44;
  &.is-open {
    overflow: hidden;
  }
}

main {
  margin: 130px 0 0;
  @include m.sp {
    margin: 8rem 0 0;
  }
}

img {
  display: block;
}

a {
  cursor: pointer;
}

.pc {
  @include m.pc {
    display: block !important;
  }

  @include m.sp {
    display: none !important;
  }
}

.pc--flex {
  @include m.pc {
    display: flex !important;
  }

  @include m.sp {
    display: none !important;
  }
}

.sp {
  @include m.pc {
    display: none !important;
  }

  @include m.sp {
    display: block !important;
  }
}

.wrap {
  @include m.wrap {
    display: none !important;
  }
}

.overContent {
  @include m.overContent {
    display: block;
  }
  @include m.content {
    display: none;
  }
  @include m.sp {
    display: none;
  }
}

.content {
  @include m.overContent {
    display: none;
  }
  @include m.content {
    display: block;
  }
}

@use 'global/mixin' as m;
@use 'global/variable' as v;

.c-image {
  width: 100%;
  &--30 {
    max-width: 3rem;
  }
  &--40 {
    max-width: 4rem;
  }
  &--100 {
    max-width: 10rem;
    &--half {
      max-width: 10rem;
      @include m.sp {
        max-width: 5rem;
      }
    }
  }
  &--120 {
    max-width: 12rem;
    &--half {
      max-width: 12rem;
      @include m.sp {
        max-width: 6rem;
      }
    }
  }
  &--140 {
    max-width: 14rem;
    &--half {
      max-width: 14rem;
      @include m.sp {
        max-width: 7rem;
      }
    }
  }
  &--150 {
    max-width: 15rem;
    &--half {
      max-width: 15rem;
      @include m.sp {
        max-width: 8rem;
      }
    }
  }
  &--180 {
    max-width: 18rem;
    &--half {
      max-width: 18rem;
      @include m.sp {
        max-width: 9rem;
      }
    }
  }
  &--200 {
    max-width: 20rem;
  }
  &--300 {
    max-width: 30rem;
  }
  &--400 {
    max-width: 40rem;
  }
  &--480 {
    max-width: 48rem;
  }
  &--500 {
    max-width: 50rem;
  }
  &--560 {
    max-width: 56rem;
  }
  &--center {
    margin: 0 auto;
  }
  &--centersp {
    @include m.sp {
      margin: 0 auto;
    }
  }
  &_xscroll {
    overflow-x: scroll;
    &Item {
      &--1000 {
        width: 100rem;
        @include m.sp {
          width: 70rem;
        }
      }
    }
  }
}

@use 'global/mixin' as m;
@use 'global/variable' as v;

.p-rinen {
  padding: 5rem 0 10rem;
  @include m.sp {
    padding: 3rem 0 6rem;
  }
  &_wrap {
    & + & {
      margin: 46rem 0 0;
      @include m.content {
        margin: 12rem 0 0;
      }
      @include m.sp {
        margin: 9rem 0 0;
      }
    }
  }
  &_title {
    &En {
      @include m.nearContent {
        font-size: 4.4rem;
      }
    }
  }
  &_band {
    position: absolute;
    background-color: #f0f5fa;
    width: 57%;
    height: 57rem;
    z-index: -1;
    top: 50%;
    transform: translateY(-50%);
    @include m.content {
      height: 57vw;
    }
    @include m.sp {
      width: 100%;
      height: 34rem;
    }
    &--left {
      border-top-right-radius: 1rem;
      border-bottom-right-radius: 1rem;
      left: 0;
      @include m.sp {
        top: 30%;
      }
    }
    &--right {
      border-top-left-radius: 1rem;
      border-bottom-left-radius: 1rem;
      right: 0;
      @include m.sp {
        top: 35%;
      }
    }
  }
  &_image {
    width: 100%;
    img {
      width: 100%;
    }
  }
}

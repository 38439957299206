@use 'global/mixin' as m;
@use 'global/variable' as v;

.p-work {
  padding: 12rem 0 18rem;
  @include m.sp {
    padding: 6rem 0;
  }
  &_background {
    background-size: cover;
    background-position: center center;
    width: 100%;
    height: 300px;
    &--print {
      background-image: url('../img/work_background_01.png');
    }
    &--clean {
      background-image: url('../img/work_background_02.png');
    }
    &--digital {
      background-image: url('../img/work_background_03.png');
    }
    @include m.sp {
      height: 200px;
    }
  }
  &_tab {
    display: flex;
    align-items: center;
    max-width: 20rem;
    padding: 0.5rem;
    width: 100%;
    position: absolute;
    border-radius: 1rem 1rem 0 0;
    bottom: 0;
    background-color: #fff;
    justify-content: center;
    @include m.sp {
      max-width: 15rem;
    }
    &--large {
      max-width: 31rem;
      @include m.sp {
        max-width: 19rem;
      }
    }

    &--long {
      max-width: 31rem;
      @include m.sp {
        max-width: 21rem;
      }
    }
  }
  &_item {
    @include m.sp {
      & + & {
        margin: 6rem 0 0;
      }
    }
  }
  &_sentence {
    max-width: 43rem;
    width: 100%;
    margin: 0 4rem 0 0;
    &--large {
      max-width: 52rem;
      margin: 0 8rem 0 0;
    }
    @include m.sp {
      margin: 0 0 2rem 0;
    }
  }
  &_balloon {
    position: absolute;
    top: -2rem;
    left: 7%;
  }
}

// Breadcrumb NavXTの見た目改ざん
.home {
  font-size: 1.4rem;
  color: #6dba44;
}

.post.post-page {
  font-size: 1.4rem;
  color: #6dba44;
}

.current-item {
  font-size: 1.4rem;
  color: #646464 !important;
}

.post-news-archive {
  font-size: 1.4rem;
  color: #6dba44;
}

@use 'global/mixin' as m;
@use 'global/variable' as v;

.p-digital {
  &About {
    background: linear-gradient(180deg, #fff 0%, #fff 3%, #e9f5e3 3%, #e9f5e3 82%, #fff 82%, #fff 100%);

    &_illust {
      @include m.pc {
        height: 140px;
      }
    }
    &_arrow {
      margin: 0 1rem;
      @include m.sp {
        display: block;
        transform: rotate(90deg);
        margin: 1rem auto;
      }
    }
  }

  &_item {
    width: 30%;
    @include m.sp {
      width: 80%;
      margin: 0 auto;
    }

    & + & {
      @include m.sp {
        margin: 4rem auto 0;
      }
    }
  }

  &Chance {
    padding: 0 0 13rem;
    @include m.sp {
      padding: 0 0 6rem;
    }

    &_left {
      @include m.pc {
        width: 56%;
      }
    }
    &_right {
      @include m.pc {
        width: 39%;
      }
    }
  }
}

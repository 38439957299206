@use 'global/mixin' as m;
@use 'global/variable' as v;

.wp-pagenavi {
  max-width: 36rem;
  width: 100%;
  margin: 6rem auto 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  @include m.sp {
    width: 90%;
    margin: 3rem auto 0;
  }
  a {
    display: block;
    width: 4rem;
    height: 4rem;
    border-radius: 50%;
    border: #6dba44 2px solid;
    @include m.sp {
      width: 3rem;
      height: 3rem;
    }
  }
  .page {
    font-weight: bold;
    border-radius: 50%;
    border: #6dba44 2px solid;
    font-size: 1.6rem;
    font-family: v.$zenMaruGothic;
    color: #6dba44;
    text-align: center;
    justify-content: center;
    align-items: center;
    display: flex;
    @include m.pc {
      transition: ease 0.3s color, ease 0.3s background-color;
      &:hover {
        background-color: #6dba44;
        color: #fff;
      }
    }
    @include m.sp {
      font-size: 1.2rem;
    }
  }
  .page {
    @include m.sp {
      & + & {
        margin: 0 0 0 1rem;
      }
    }
  }
  .current {
    font-weight: bold;
    width: 4rem;
    height: 4rem;
    border-radius: 50%;
    border: #6dba44 2px solid;
    font-size: 1.6rem;
    font-family: v.$zenMaruGothic;
    color: #fff;
    background-color: #6dba44;
    text-align: center;
    justify-content: center;
    align-items: center;
    display: flex;
    @include m.sp {
      font-size: 1.2rem;
      width: 3rem;
      height: 3rem;
    }
  }
  .previouspostslink,
  .nextpostslink {
    position: relative;
    @include m.pc {
      transition: ease 0.3s color, ease 0.3s background-color;
      &:hover {
        background-color: #6dba44;
        &::after {
          background-color: #fff;
        }
      }
    }
    &::before {
      content: '';
      width: 0;
      height: 0;
      top: 50%;
      position: absolute;
      transform: translate(-50%, -50%);
      @include m.pc {
        transition: ease 0.3s border-color;
      }
    }
    &::after {
      content: '';
      width: 1.2rem;
      height: 0.3rem;
      background-color: #6dba44;
      left: 50%;
      top: 50%;
      position: absolute;
      transform: translate(-50%, -50%);
      @include m.pc {
        transition: ease 0.3s background-color;
      }
      @include m.sp {
        width: 0.8rem;
        height: 0.2rem;
      }
    }
  }
  .nextpostslink {
    &::before {
      border-left: 0.6rem solid #6dba44;
      border-top: 0.4rem solid transparent;
      border-bottom: 0.4rem solid transparent;
      left: 65%;
    }
    &:hover {
      &::before {
        border-left: 0.6rem solid #fff;
        border-top: 0.4rem solid transparent;
        border-bottom: 0.4rem solid transparent;
      }
    }
  }
  .previouspostslink {
    &::before {
      border-top: 0.4rem solid transparent;
      border-right: 0.6rem solid #6dba44;
      border-bottom: 0.4rem solid transparent;
      left: 35%;
    }
    &:hover {
      &::before {
        border-top: 0.4rem solid transparent;
        border-right: 0.6rem solid #fff;
        border-bottom: 0.4rem solid transparent;
      }
    }
  }
}

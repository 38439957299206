@use 'global/mixin' as m;
@use 'global/variable' as v;

.l-wrap {
  width: 100%;
  margin: 0 auto;
  padding: 0 20px;
  box-sizing: border-box;

  @include m.pc {
    max-width: 1040px; // 各デザイン毎に変更
  }

  @include m.sp {
    width: 90%;
    margin: 0 auto;
    padding: 0;
  }
  &--left {
    @include m.content {
      max-width: initial;
      margin: 0;
      padding: 0 0 0 20px;
    }
  }
  &--right {
    @include m.content {
      max-width: initial;
      margin: 0;
      padding: 0 20px 0 0;
    }
  }
  &--work {
    height: 300px;
    @include m.sp {
      height: 200px;
    }
  }
}

@use 'global/mixin' as m;
@use 'global/variable' as v;

.c-circle {
  border-radius: 50%;
  background-color: #005bac;
  width: 8rem;
  height: 8rem;
  color: #fff;
  font-weight: bold;
  font-size: 2.4rem;
  text-align: center;
  line-height: 8rem;
  font-family: v.$zenMaruGothic;
  @include m.sp {
    font-size: 1.4rem;
    width: 4rem;
    height: 4rem;
    line-height: 4rem;
  }
  &--reverse {
    background-color: #fff;
    color: #005bac;
  }
  &--small {
    width: 6.4rem;
    height: 6.4rem;
    font-size: 2rem;
    line-height: 6.4rem;
    @include m.sp {
      font-size: 1.4rem;
      width: 4rem;
      height: 4rem;
      line-height: 4rem;
    }
  }
}

@use 'global/mixin' as m;
@use 'global/variable' as v;

.c-arrow {
  position: relative;
  &::after {
    content: url('../img/icon_arrow.svg');
    position: absolute;
    max-width: 2.6rem;
    width: 100%;
    transform: translateY(-50%);
    top: 50%;
    right: 5%;
    @include m.sp {
      max-width: 1.8rem;
    }
  }
  &--link {
    &::after {
      top: 55%;
      max-width: 1.8rem;
      content: url('../img/icon_link.svg');
    }
  }
  &--linkw {
    &::after {
      top: 55%;
      right: 28%;
      max-width: 1.8rem;
      content: url('../img/icon_link_white.svg');
    }
  }
  &--limegreen {
    &::after {
      content: url('../img/icon_arrow_limegreen.svg');
    }
  }
  &--darkgreen {
    &::after {
      content: url('../img/icon_arrow_darkgreen.svg');
    }
  }
  &--hamburger {
    @include m.sp {
      &::after {
        content: url('../img/icon_arrow_limegreen.svg');
      }
    }
  }
  &--hub {
    &::after {
      transform: translate(50%, -50%);
      top: 65%;
      right: 50%;
    }
  }
  &--band {
    &::after {
      @include m.sp {
        max-width: 3.6vw;
        top: 45%;
      }
    }
  }
  &--footer {
    &::after {
      max-width: 2rem;
      top: 30%;
      right: 0%;
    }
  }
  &--download {
    &::after {
      max-width: 2rem;
      top: 45%;
      right: 3%;
    }
  }
}

@use 'global/mixin' as m;
@use 'global/variable' as v;

@for $i from 0 through 20 {
  .u-pl_#{$i} {
    padding-left: #{$i}rem;
    @include m.sp {
      padding-left: calc(#{$i}rem/ 2);
    }
  }
}

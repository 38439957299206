@use 'global/mixin' as m;
@use 'global/variable' as v;

.c-icon {
  width: 100%;
  &--phone {
    max-width: 1.6rem;
  }
  &--fax {
    max-width: 2.5rem;
  }
  &--print {
    max-width: 2.4rem;
  }
  &--factory {
    max-width: 3.3rem;
  }
  &--clean {
    max-width: 3.8rem;
  }
  &--paper {
    max-width: 3.9rem;
  }
  &--stop {
    max-width: 5rem;
  }
  &--delivery {
    max-width: 5.8rem;
  }
}

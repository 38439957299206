.c-decoration {
  position: relative;
  display: inline-block;
  &::after {
    content: '';
    position: absolute;
    width: 5.4rem;
    height: 2px;
    background-color: currentColor;
    top: 50%;
    right: -6.5rem;
    transform: translateY(-50%);
  }
}

@use 'global/mixin' as m;
@use 'global/variable' as v;

.c-link {
  color: #6dba44;
  transition: opacity 0.3s ease;

  &:hover {
    @include m.pc {
      opacity: 0.7;
    }
  }
}

@use 'global/mixin' as m;
@use 'global/variable' as v;

// radio button css
.mwform-radio-field > label {
  cursor: pointer;
  display: block;
  @include m.sp {
    & + & {
      margin: 1rem 0 0;
    }
  }
}

.mwform-radio-field-text {
  position: relative;
  font-size: 1.4rem;
  font-weight: 500;
  display: inline-block;
  padding: 0 0 0 3rem;
  @include m.sp {
    font-size: 1.2rem;
  }
  &::before {
    content: '';
    display: block;
    position: absolute;
    transform: translateY(-50%);
    top: 50%;
    background-color: #f6f6f6;
    border: 0.1rem solid #8f8f8f;
    border-radius: 50%;
    width: 2rem;
    height: 2rem;
    left: 0rem;
  }
  &::after {
    content: '';
    display: block;
    position: absolute;
    transform: translateY(-50%);
    top: 50%;
    background-color: #6dba44;
    border-radius: 50%;
    opacity: 0;
    width: 1.6rem;
    height: 1.6rem;
    left: 0.2rem;
  }
}

.mwform-radio-field.horizontal-item {
  &:nth-child(1) {
    flex-basis: 18%;
  }
  &:nth-child(2) {
    flex-basis: 21%;
  }
  &:nth-child(3) {
    flex-basis: 18%;
  }
  &:nth-child(4) {
    flex-basis: 18%;
  }
  &:nth-child(5) {
    flex-basis: 18%;
  }
}

.p-faq_radioInput:checked + .mwform-radio-field-text::after {
  opacity: 1;
}

// checkbox css
.mwform-checkbox-field > label {
  display: block;
  text-align: center;
  cursor: pointer;
  @include m.sp {
    text-align: left;
  }
}

.mwform-checkbox-field-text {
  font-size: 1.6rem;
  position: relative;
  padding: 0 0 0 5rem;
  margin: 0 4rem 0 0;
  @include m.sp {
    font-size: 1.2rem;
    padding: 0 0 0 4.5rem;
    margin: 0 0 0 0;
  }
  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: 3rem;
    height: 3rem;
    border: 1px solid #8f8f8f;
    background-color: #f6f6f6;
  }
  &::after {
    content: '';
    position: absolute;
    left: 4%;
    top: 50%;
    width: 1rem;
    height: 2rem;
    border: solid #6dba44;
    border-width: 0 3px 3px 0;
    transform: translateY(-50%) rotate(45deg);
    opacity: 0;
    @include m.sp {
      left: 5%;
    }
  }
}

.mw_wp_form_confirm {
  .p-faq_checkboxContainer {
    display: none;
  }
}

.p-faq_checkboxInput:checked + .mwform-checkbox-field-text::after {
  opacity: 1;
}

.p-faq_checkboxContainer > .error {
  text-align: center;
}

// textarea css
.mw_wp_form_confirm {
  .p-faq_term {
    display: none;
  }
  .p-faq_caution {
    display: none;
  }
}

// special case css
.mw_wp_form_confirm {
  .p-faq_box {
    display: flex;
    align-items: center;
    @include m.sp {
      display: block;
    }
  }
  .p-faq_case {
    margin: 0;
    display: block;
    @include m.sp {
      margin: 1rem 0 0;
    }
  }
  .p-faq_submitContainer {
    margin: 7rem auto 0;
    @include m.sp {
      margin: 3.5rem auto 0;
    }
  }
  .p-faq_container--file {
    display: none;
  }
  .p-faq_answer {
    padding: 1.5rem;
    background-color: #f5f5f5;
    border-radius: 1rem;
    @include m.sp {
      margin: 1rem 0 0;
      padding: 1rem;
    }
  }
}

.mwform-file-delete {
  margin: 0 0 0 1rem;
  display: none;
}

.mw-wp-form_file {
  display: none;
}

.mw_wp_form .error {
  font-size: 1.4rem !important;
  @include m.sp {
    font-size: 1.2rem !important;
  }
}

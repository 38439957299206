@use 'global/mixin' as m;
@use 'global/variable' as v;

.c-card {
  border-radius: 1rem;
  max-width: 30rem;
  width: 100%;
  @include m.sp {
    width: 90%;
    margin: 0 auto;
  }
  &--shadow {
    box-shadow: 5px 5px 5px #7aba4333;
  }
  &_image {
    max-width: 30rem;
    width: 100%;
    &--small {
      max-width: 16rem;
      @include m.sp {
        max-width: 10rem;
      }
    }
    &--kouzyou01,
    &--kouzyou02 {
      max-width: 20rem;
      @include m.sp {
        max-width: initial;
        background-repeat: no-repeat;
        background-size: cover;
        height: 12rem;
        width: 100%;
        border-radius: 1rem 1rem 0 0;
      }
      & > img {
        @include m.pc {
          border-radius: 1rem 0 0 1rem;
        }
      }
    }
    &--kouzyou01 {
      @include m.sp {
        background-image: url('../img/hub_image_06.png');
      }
    }
    &--kouzyou02 {
      @include m.sp {
        background-image: url('../img/hub_image_07.png');
      }
    }
  }
  &_caption {
    background-color: #fff;
    width: 100%;
    &--top {
      max-width: 30rem;
      padding: 3.5rem 3rem 5rem 3rem;
      border-radius: 0 0 1rem 1rem;
      @include m.sp {
        padding: 1.7rem 1.5rem 2.5rem 1.5rem;
      }
    }
    &--small {
      text-align: center;
      padding: 2rem 2rem 2rem 2.5rem;
      border-radius: 0 0 1rem 1rem;
    }
    &--hub {
      width: 100%;
      padding: 0 0 2rem 0;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 0 1rem 1rem 0;
    }
    &--work {
      padding: 3rem 3rem 2rem 3rem;
      border-radius: 0 1rem 1rem 0;
      @include m.sp {
        padding: 2rem 2rem 2rem 2rem;
        border-radius: 0 0 1rem 1rem;
      }
    }
    &--about {
      border-radius: 0 0 1rem 1rem;
      text-align: center;
      background-color: #6dba44;
      padding: 2rem;
    }
  }
  &_hub {
    display: flex;
    box-shadow: 5px 5px 5px #7aae4333;
    border-radius: 1rem;
    &--column {
      @include m.sp {
        flex-direction: column;
      }
    }
  }
}

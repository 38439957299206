@use 'global/mixin' as m;
@use 'global/variable' as v;
@use 'global/keyframes' as k;

.c-map {
  &_container {
    display: flex;
    justify-content: space-between;
    max-width: 65rem;
    width: 100%;
    &--header {
      max-width: 100rem;
      @include m.sp {
        background-color: #fff;
        border-radius: 1rem;
        display: block;
        padding: 2rem 1.2rem;
      }
    }
    &--footer {
      @include m.sp {
        display: none;
      }
    }
  }
  &_list {
    width: 100%;
    @include m.pc {
      max-width: 15rem;
    }

    & + & {
      @include m.pc {
        margin: 0 0 0 2rem;
      }

      @include m.sp {
        margin: 2rem 0 0;
      }
    }

    &Inner {
      & + & {
        @include m.pc {
          margin: 2rem 0 0;
        }
      }

      @include m.sp {
        flex-basis: 48%;
        margin: 1rem 0 0;
      }

      &--large {
        @include m.sp {
          flex-basis: 100%;
        }
      }
    }
  }
  &_icon {
    max-width: 1.8rem;
    width: 100%;
    @include m.pc {
      display: inline-block;
    }

    @include m.sp {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: -15%;
    }
  }
  &_inner {
    margin: 2rem 0 0;
    @include m.sp {
      margin: 1rem 0 0;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
  }
  &_link {
    display: block;
    font-size: 1.6rem;
    letter-spacing: 0.06em;
    color: #fff;
    font-weight: bold;
    font-family: v.$zenMaruGothic;
    @include m.pc {
      transition: opacity 0.3s ease;
      &:hover {
        opacity: 0.7;
      }
    }
    @include m.sp {
      font-size: 1.4rem;
      color: #2e3b27;
    }
    & + & {
      margin: 4rem 0 0;
      @include m.sp {
        margin: 2rem 0 0;
      }
    }
    &--line {
      border-bottom: 2px solid #fff;
      padding: 0 0 1rem 0;
      @include m.sp {
        border-bottom: 2px dashed #6dba44;
        padding: 0 0 0.5rem 0;
      }
    }
    &--small {
      font-size: 1.2rem !important;
    }
    &--inner {
      font-size: 1.4rem;
      position: relative;
      padding-left: 12px;
      @include m.sp {
        display: inline-block;
        font-size: 1.2rem;
        &::after {
          content: '';
          width: calc(100% - 12px);
          height: 1px;
          position: absolute;
          left: 12px;
          background-color: #6dba44;
          bottom: 0;
        }
      }
      &::before {
        content: '';
        background-color: #fff;
        width: 8px;
        height: 2px;
        position: absolute;
        left: 0%;
        top: 50%;
        @include m.sp {
          content: '';
          background-color: #6dba44;
        }
      }
    }
  }
}

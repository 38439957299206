.u-fw {
  &_thin {
    font-weight: 100;
  }
  &_extraLight {
    font-weight: 200;
  }
  &_light {
    font-weight: 300;
  }
  &_normal {
    font-weight: 400;
  }
  &_medium {
    font-weight: 500;
  }
  &_semiBold {
    font-weight: 600;
  }
  &_bold {
    font-weight: 700;
  }
  &_extraBold {
    font-weight: 800;
  }
  &_black {
    font-weight: 900;
  }
}

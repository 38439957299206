@use 'global/mixin' as m;
@use 'global/variable' as v;

.p-kouzyou {
  &About {
    padding: 12rem 0 0;
    @include m.sp {
      padding: 2rem 0 0;
    }
    &_gradient {
      padding: 10rem 0 9rem;
      background: linear-gradient(180deg, #fff 0%, #fff 6%, #e9f5e3 6%, #e9f5e3 100%);
      @include m.sp {
        padding: 6rem 0;
      }
    }
    &_container {
      max-width: 79rem;
      width: 100%;
      margin: 17rem auto 0;
      @include m.sp {
        margin: 4rem auto 0;
      }
    }
  }
  &Program {
    padding: 16rem 0 22rem;
    @include m.sp {
      padding: 6rem 0;
    }
    &_frame {
      border: 2px solid #6dba44;
      border-radius: 1rem;
      background-color: #fff;
      padding: 3rem 2rem;
      @include m.sp {
        border-radius: 0.5rem;
        padding: 4rem 2rem 3rem 2rem;
      }
      &--darkgreen {
        border: 2px solid #3a9e80;
      }
    }
    &_illust {
      max-width: 91rem;
      width: 100%;
      margin: 0 auto;
    }
    &_balloon {
      top: -2rem;
      left: 2rem;
      @include m.sp {
        left: 50%;
        transform: translateX(-50%);
      }
    }
    &_fill {
      background-color: #6dba44;
      border-radius: 1rem;
      padding: 2rem 5%;
      &--darkgreen {
        background-color: #3a9e80;
      }
    }
    &_button {
      text-align: left;
      line-height: 2;
      font-size: 1.8rem;
      border-radius: 1rem;
      padding: 2rem 4rem 2rem 3rem;
      @include m.wrap {
        max-width: 33rem;
      }
      @include m.sp {
        font-size: 1.4rem;
        padding: 1rem 2rem;
      }
    }
    &_box {
      @include m.pc {
        border-right: 2px solid #fff;
        padding: 1rem 7% 1rem 0;
        margin-right: 5%;
      }
      @include m.sp {
        border-bottom: 2px solid #fff;
        padding-bottom: 2rem;
        margin-bottom: 2rem;
      }
    }
  }
}

@use 'global/mixin' as m;
@use 'global/variable' as v;

.p-recruit {
  &About {
    padding: 12rem 0 0;
    @include m.sp {
      padding: 2rem 0 0;
    }
    &_frame {
      padding: 6rem 10% 9rem;
      @include m.sp {
        padding: 4rem 10%;
      }
    }
    &_balloon {
      position: absolute;
      top: -1.5rem;
      left: 10%;
    }
    &_image {
      max-width: 39rem;
      width: 100%;
      flex-basis: 49%;
      & + & {
        @include m.sp {
          margin: 2rem auto 0;
        }
      }
    }
    &_gradient {
      padding: 10rem 0 9rem;
      background: linear-gradient(180deg, #fff 0%, #fff 4%, #e9f5e3 4%, #e9f5e3 50%, #fff 50%, #fff 100%);
      @include m.sp {
        padding: 6rem 0;
        background: linear-gradient(180deg, #fff 0%, #fff 3%, #e9f5e3 3%, #e9f5e3 80%, #fff 80%, #fff 100%);
      }
    }
  }
  &Guide {
    padding: 0 0 20rem;
    @include m.sp {
      padding: 0 0 6rem;
    }
    &_button {
      @include m.sp {
        & + & {
          margin: 2rem auto 0;
        }
      }
    }
    &_link {
      display: block;
      text-decoration: underline;
      color: #6dba44;
    }
  }
}

.c-lap {
  position: relative;
  z-index: 0;
  display: inline-block;
  &_en {
    position: absolute;
    left: 0;
    top: 40%;
    transform: translateY(-50%);
    z-index: -1;
  }
}

@use 'global/mixin' as m;
@use 'global/variable' as v;

@for $i from 0 through 20 {
  .u-mt_#{$i} {
    margin-top: #{$i}rem;
    @include m.sp {
      margin-top: calc(#{$i}rem/ 2);
    }
  }
}

.u-mt_2--sp {
  @include m.sp {
    margin-top: 2rem;
  }
}

.u-mt_3--sp {
  @include m.sp {
    margin-top: 3rem;
  }
}

.u-mt_14--corp {
  @include m.sp {
    margin-top: 2rem;
  }
}

@use 'global/mixin' as m;
@use 'global/variable' as v;

.l-header {
  padding: 2.6rem 0 0rem;
  position: fixed;
  z-index: 99;
  width: 100%;
  top: 0;
  left: 0;
  background: #fff;
  border-top: 10px solid #6dba44;
  border-left: 10px solid #6dba44;
  border-right: 10px solid #6dba44;
  @include m.sp {
    padding: 1.3rem 0 0rem;
  }
  &_wrap {
    width: 100%;
    margin: 0 auto;
    padding: 0 20px;
    box-sizing: border-box;
    max-width: 1040px;

    @include m.header {
      width: 90%;
      margin: 0;
    }

    @include m.tab {
      width: 90%;
      margin: 0;
    }

    @include m.sp {
      width: 90%;
      margin: 0 auto;
      padding: 0;
    }
  }
  &_container {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding: 0 0 2rem;
    @include m.sp {
      padding: 0 0 1rem;
    }
  }
  &_menu {
    display: flex;
    justify-content: space-between;
    margin: 2rem 0 0;
    @include m.tab {
      margin: 1rem 0 0;
    }
    @include m.sp {
      display: none;
    }
  }
  &_phone {
    display: flex;
    align-items: baseline;
    max-width: 20rem;
    width: 100%;
    justify-content: space-between;
    @include m.tab {
      max-width: 16rem;
    }
    @include m.sp {
      max-width: 46vw;
    }
    &Icon {
      @include m.sp {
        max-width: 3.2vw !important;
      }
    }
    &Number {
      @include m.tab {
        font-size: 2.2rem !important;
      }
      @include m.sp {
        font-size: 6.2vw !important;
      }
    }
  }
  &_contact {
    display: flex;
    justify-content: flex-end;
    @include m.sp {
      display: block;
      margin: 0 0 0 3vw;
    }
  }
  &_info {
    display: flex;
    max-width: 32rem;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    @include m.tab {
      max-width: 28rem;
    }
    @include m.sp {
      display: block;
    }
  }
  &_other {
    @include m.sp {
      width: 100%;
      display: flex;
    }
    &Text {
      @include m.sp {
        font-size: 2.6vw !important;

        & + & {
          margin-left: 1rem;
        }
      }
    }
  }
  &_contents {
    max-width: 60rem;
    width: 100%;
    @include m.tab {
      margin: 0 0 0 1rem;
    }
  }
  &_link {
    font-size: 1.6rem;
    font-family: v.$zenMaruGothic;
    letter-spacing: 0.06em;
    font-weight: 500;
    transition: ease 0.4s color;
    position: relative;
    @include m.tab {
      font-size: 1.4rem;
    }
    &::before {
      content: '';
      height: 2px;
      width: 0;
      background-color: #6dba44;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: -20%;
      transform-origin: left;
      transition: ease 0.4s width;
    }
    &.is-view {
      color: #6dba44;
      &::before {
        width: 2rem;
      }
    }
    &--inner {
      color: #fff;
    }
    &--arrow {
      position: relative;
      &::after {
        content: '';
        position: absolute;
        border-bottom: solid 2px currentColor;
        border-right: solid 2px currentColor;
        width: 8px;
        height: 8px;
        top: 50%;
        right: -20%;
        transform: translateY(-50%) rotate(45deg);
      }
    }
  }
  &_list {
    &--inner {
      & + & {
        margin: 2rem 0 0;
      }
    }
  }
  &_inner {
    background-color: #6dba44;
    border-radius: 1rem;
    padding: 2rem;
    position: absolute;
    width: 19rem;
    top: 5rem;
    left: 50%;
    transform: translateX(-50%);
    z-index: 2;
    &::before {
      content: '';
      position: absolute;
      left: 50%;
      top: -1.5rem;
      transform: translateX(-50%);
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 10px 17.3px 10px;
      border-color: transparent transparent #6dba44 transparent;
    }

    &--large {
      width: 32rem;
    }
  }
  &_view {
    opacity: 0;
    pointer-events: none;
    width: 100%;
    height: 200%;
    transition: ease 0.4s opacity;
    &.is-view {
      opacity: 1;
      pointer-events: auto;
    }
  }
}

@use 'global/mixin' as m;
@use 'global/variable' as v;

.c-frame {
  padding: 1rem 2rem;
  display: flex;
  align-items: center;
  border: #005bac 2px solid;
  border-radius: 1rem;
  max-width: 24rem;
  width: 100%;
  @include m.sp {
    padding: 0.5rem 2rem;
    margin: 0 auto;
  }
  &--white {
    border: #fff 2px solid;
  }
  &--small {
    padding: 0.4rem;
    max-width: 20rem;
    @include m.sp {
      max-width: 24rem;
    }
  }
  &--faq {
    padding: 2rem;
    @include m.sp {
      padding: 1rem;
      justify-content: center;
    }
  }
  &--center {
    justify-content: center;
  }
  &--oval {
    border-radius: 10rem;
  }
  &--hamburger {
    @include m.wrap {
      max-width: 5rem;
      border: none;
    }
  }
  &--work {
    max-width: 100rem;
    justify-content: space-between;
    padding: 6% 5% 6% 7%;
    border: 2px solid #6dba44;
    @include m.sp {
      display: block;
      padding: 3rem 2rem 2rem 2rem;
    }
  }
  &--print {
    align-items: flex-start;
    border: 2px solid #6dba44;
    justify-content: space-between;
    max-width: 100rem;
    background-color: #fff;
    padding: 6rem 5% 6rem 7%;
    @include m.sp {
      display: block;
      padding: 4rem 2rem 2rem 2rem;
    }
  }
  &_fill {
    background-color: #fff;
    border-radius: 1rem;
    padding: 5rem;
  }
}

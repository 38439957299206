@use 'global/mixin' as m;
@use 'global/variable' as v;

.c-hub {
  position: relative;
  padding: 9rem 0;
  background-color: #e9f5e3;
  @include m.sp {
    padding: 6rem 0;
  }
  &Band {
    position: absolute;
    right: 0;
    top: -4rem;
    background-color: #6dba44;
    border-top-left-radius: 1rem;
    border-bottom-left-radius: 1rem;
    padding: 5rem 0 7rem 5rem;
    max-width: 46rem;
    width: 100%;
    @include m.sp {
      max-width: 23rem;
      padding: 5vw;
    }
    &_title {
      color: #fff;
      font-size: 2.8rem;
      letter-spacing: 0.06em;
      font-family: v.$zenMaruGothic;
      @include m.sp {
        font-size: 1.8rem;
      }
    }
    &_text {
      color: #fff;
      font-size: 1.4rem;
      letter-spacing: 0.06em;
      font-family: v.$zenMaruGothic;
    }
  }
}

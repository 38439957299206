@use 'global/mixin' as m;
@use 'global/variable' as v;

.c-subTitle {
  font-size: 2.6rem;
  font-family: v.$zenMaruGothic;
  font-weight: 500;
  letter-spacing: 0.06em;
  @include m.sp {
    font-size: 2rem;
  }
  &--white {
    color: #fff;
  }
  &--limegreen {
    color: #6dba44;
  }
  &--center {
    text-align: center;
  }
  &--small {
    font-size: 2.2rem;
    @include m.sp {
      font-size: 1.6rem;
    }
  }
  &--tiny {
    font-size: 1.8rem;
    font-weight: bold;
    color: #6dba44;
  }
}

@use 'global/mixin' as m;
@use 'global/variable' as v;

.c-list {
  border-top: 2px solid #6dba44;
  padding: 3.5rem 0;

  @include m.sp {
    padding: 1.8rem 0;
  }

  &:last-child {
    border-bottom: 2px solid #6dba44;
  }
}

@use 'global/mixin' as m;
@use 'global/variable' as v;

@for $i from 0 through 20 {
  .u-ml_#{$i} {
    margin-left: #{$i}rem;
    @include m.sp {
      margin-left: calc(#{$i}rem/ 2);
    }
  }
}

.u-ml_1p5 {
  margin-left: 1.5rem;
}

@use 'global/mixin' as m;
@use 'global/variable' as v;

.c-hero {
  &_space {
    @include m.pc {
      padding: 0 0 5rem 9rem;
    }
    &--news {
      @include m.pc {
        margin: 19rem 0 -46rem;
        padding: 0 0 0rem 9rem;
      }
      @include m.sp {
        margin: 16rem 0 0;
      }
    }
    &--left {
      @include m.pc {
        padding: 0 0 0rem 9rem;
      }
    }
  }
  &_wrap {
    &--sp {
      @include m.sp {
        width: 90%;
        margin: 0 auto;
      }
    }
  }
  &_gradient {
    background: linear-gradient(180deg, #fff 0%, #fff 80%, #e9f5e3 80%, #e9f5e3 100%);
  }
  &_inner {
    position: relative;
    max-width: 1240px;
    width: 100%;
    margin: 0 auto;
    padding: 0 20px;
    @include m.sp {
      width: 90%;
      margin: 0 auto;
      padding: 0;
    }
  }
  &_illust {
    &--top {
      max-width: 8rem;
      width: 100%;
      @include m.sp {
        max-width: 4rem;
      }
    }
    &--bottom {
      position: absolute;
      bottom: -6rem;
      right: -20px;
      max-width: 30rem;
      width: 100%;
      z-index: 1;
      @include m.nearContent {
        right: 0;
      }
      @include m.sp {
        max-width: 10rem;
        right: -1rem;
        bottom: -3rem;
      }
    }
  }
  &_image {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    max-width: 1200px;
    width: 100%;
    height: 650px;
    border-radius: 1rem 0 0 1rem;
    @include m.pc {
      margin: 0 auto;
    }
    @include m.sp {
      height: 100vw;
      border-radius: 1rem;
      width: 90%;
      margin: 0 auto;
    }
    &--01 {
      background-image: url('../img/top_hero_01.png');
    }
    &--02 {
      background-image: url('../img/top_hero_02.png');
    }
    &--03 {
      background-image: url('../img/top_hero_03.png');
    }
    &--04 {
      background-image: url('../img/top_hero_04.png');
    }
    &--05 {
      background-image: url('../img/top_hero_05.png');

      @include m.sp {
        background-image: url('../img/top_hero_05_sp.png');
      }
    }

    &--sub {
      max-width: initial;
      height: 420px;
      background-image: url('../img/corp_hero_01.png');
      @include m.sp {
        height: 85vw;
      }
    }
    &--news {
      max-width: initial;
      height: 600px;
      background-image: url('../img/news_hero_01.png');
      @include m.sp {
        height: 85vw;
        display: none;
      }
    }
    &--corp {
      background-image: url('../img/corp_hero_01.png');
    }
    &--info {
      background-image: url('../img/corp_hero_02.png');
    }
    &--rinen {
      background-image: url('../img/rinen_hero_01.png');
    }
    &--work {
      background-image: url('../img/work_hero_01.png');
    }
    &--print {
      background-image: url('../img/print_hero_01.png');
    }
    &--kouzyou {
      background-image: url('../img/kouzyou_hero_01.png');
    }
    &--eco {
      background-image: url('../img/eco_hero_01.png');
    }
    &--recruit {
      background-image: url('../img/recruit_hero_01.png');
    }
    &--history {
      background-image: url('../img/history_hero_01.png');
    }
    &--digital {
      background-image: url('../img/digital_hero_01.png');
    }
  }
}

@use 'global/mixin' as m;
@use 'global/variable' as v;

@for $i from 0 through 20 {
  .u-pr_#{$i} {
    padding-right: #{$i}rem;
    @include m.sp {
      padding-right: calc(#{$i}rem/ 2);
    }
  }
}

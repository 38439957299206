@use 'global/mixin' as m;
@use 'global/variable' as v;
@use 'global/keyframes' as k;

.c-button {
  text-decoration: none;
  display: block;
  font-size: 1.6rem;
  text-align: center;
  border: transparent 2px solid;
  font-family: v.$zenMaruGothic;
  @include m.sp {
    font-size: 1.2rem;
  }
  &:hover {
    @include m.pc {
      animation: rotate 0.7s ease-in-out;
    }
  }
  &--oval {
    border-radius: 10rem;
  }
  &--center {
    margin: 0 auto;
  }
  &--limegreen {
    background-color: #6dba44;
    color: #fff;
    &--reverse {
      border: #6dba44 2px solid;
      color: #6dba44;
      background-color: #fff;
    }
  }
  &--darkgreen {
    background-color: #3a9e80;
    color: #fff;
    &--reverse {
      border: #3a9e80 2px solid;
      color: #3a9e80;
      background-color: #fff;
    }
  }
  &_disable {
    background-color: #8f8f8f;
    color: #fff;
  }
  &--royalblue {
    background-color: #005bac;
    color: #fff;
    &--reverse {
      border: #005bac 2px solid;
      color: #005bac;
    }
  }
  &--header {
    font-family: v.$zenMaruGothic;
    font-size: 1.4rem;
    font-weight: bold;
    max-width: 12rem;
    width: 100%;
    padding: 0.8rem;

    @include m.pc {
      margin-left: 1rem;
    }

    @include m.tab {
      font-size: 1.2rem;
      max-width: 10rem;
    }
  }
  &--large {
    max-width: 36rem;
    width: 100%;
    padding: 1.6rem;
  }
  &--430 {
    max-width: 43rem;
    width: 100%;
  }
  &--download {
    max-width: 27.5rem;
    font-weight: bold;
    width: 100%;
    padding: 1rem 1rem 1rem 2rem;
    margin: 0 0 0 3rem;
    text-align: left;
    @include m.sp {
      text-align: left;
      font-size: 1.6rem;
      padding: 1rem;
      margin: 1rem auto 0;
    }
  }
  &--small {
    max-width: 20rem;
    width: 100%;
    padding: 0.8rem;
  }
  &--reverse {
    border: #fff 2px solid;
    color: #fff;
  }
  &--greenframe {
    background-color: #fff;
    border: #6dba44 2px solid;
    color: #6dba44;
  }
  &--contact {
    font-size: 2.4rem;
    font-family: v.$zenMaruGothic;
    background-color: #005bac;
    color: #fff;
    border-radius: 1rem;
    max-width: 50rem;
    width: 100%;
    padding: 4rem;
    margin: 0 auto;
    @include m.sp {
      font-size: 1.8rem;
      padding: 2rem;
    }
  }
  &--hamburger {
    max-width: 29rem;
    padding: 2.8rem;
    font-size: 1.6rem;
    margin: 0;
    @include m.sp {
      padding: 1.4rem;
      font-size: 1.2rem;
    }
  }
  &--recruit {
    max-width: 47rem;
    width: 100%;
    padding: 5rem 3rem;
    border-radius: 1rem;
    flex-basis: 47%;
    @include m.sp {
      padding: 2rem 1rem;
    }
  }
}

@use 'global/mixin' as m;
@use 'global/variable' as v;

.c-illust {
  position: relative;
  &--top {
    max-width: 18rem;
    width: 100%;
    padding: 0 0 3rem 0;
    margin: -20rem 0rem 0 auto;
    @include m.sp {
      max-width: 9rem;
      padding: 1rem 0 0 0;
      margin: -10rem 0rem 0 auto;
    }
  }
  &_hamburger {
    max-width: 10rem;
    width: 100%;
    position: absolute;
    @include m.sp {
      max-width: 5rem;
    }
    &--left {
      left: -5rem;
      bottom: -5rem;
      z-index: 1;
      @include m.header {
        left: 0;
        bottom: -8rem;
      }
      @include m.tab {
        left: 0;
        bottom: -8rem;
      }
      @include m.sp {
        left: 0;
      }
    }
    &--right {
      right: -5rem;
      bottom: -5rem;
      z-index: 1;
      @include m.header {
        right: 0;
        bottom: -8rem;
      }
      @include m.tab {
        right: 0;
        bottom: -8rem;
      }
      @include m.sp {
        right: 0;
      }
    }
  }
}

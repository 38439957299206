@use 'global/mixin' as m;
@use 'global/variable' as v;

.c-underline {
  position: relative;
  &::before {
    content: '';
    position: absolute;
    left: 50%;
    bottom: -3rem;
    transform: translateX(-50%);
    width: 2.6rem;
    height: 0.3rem;
    border-radius: 1rem;
    background-color: #6dba44;
    @include m.sp {
      bottom: -1.5rem;
    }
  }
  &--white {
    &::before {
      background-color: #fff;
    }
  }
  &_title {
    position: relative;
    &::after {
      content: '';
      position: absolute;
      height: 2px;
      width: 3rem;
      background-color: currentColor;
      left: 0%;
      top: calc(100% + 2.5rem);
      @include m.sp {
        top: calc(100% + 1rem);
      }
    }
    &--wide {
      &::after {
        top: calc(100% + 3rem);
        @include m.sp {
          top: calc(100% + 1.5rem);
        }
      }
    }
  }
  &_separate {
    border-bottom: solid 1px #8f8f8f;
    padding-bottom: 1.5rem;
    margin-bottom: 1.5rem;
    @include m.sp {
      padding-bottom: 1rem;
      margin-bottom: 1rem;
    }
  }
}

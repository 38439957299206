@use 'global/mixin' as m;
@use 'global/variable' as v;

.c-table {
  &_title {
    flex-basis: 22%;
  }
  &_contents {
    flex: 1;
    &--wide {
      @include m.sp {
        margin: 4rem 0 0;
      }
    }
  }
  &_text {
    flex: 1;
    padding: 3rem 2rem;
    @include m.sp {
      padding: 0 1rem 1rem 1rem;
    }
    &--narrow {
      padding: 2.5rem;
      flex-basis: 16%;
      @include m.sp {
        padding: 0 1rem 1rem 1rem;
      }
    }
  }
  &_column {
    padding: 3rem 2rem;
    flex-basis: 28%;
    @include m.sp {
      padding: 1rem;
    }
    &--narrow {
      padding: 2.5rem 4rem;
      flex-basis: 16%;
      @include m.sp {
        padding: 1rem;
      }
    }
  }
  &_background {
    &--info {
      &:nth-of-type(2n + 1) {
        background-color: #ededed;
      }
    }
    &--recruit {
      &:nth-of-type(odd) {
        background-color: #f5f5f5;
      }
    }
  }
}

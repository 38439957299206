@mixin pc($width: 768px) {
  @media screen and (min-width: $width) {
    @content;
  }
}

@mixin tab {
  @media screen and (min-width: 768px) and (max-width: 900px) {
    @content;
  }
}

@mixin sp($width: 767px) {
  @media screen and (max-width: $width) {
    @content;
  }
}

@mixin header {
  @media screen and (min-width: 901px) and (max-width: 1200px) {
    @content;
  }
}

@mixin wrap {
  @media screen and (min-width: 768px) and (max-width: 1000px) {
    @content;
  }
}

@mixin nearContent {
  @media screen and (min-width: 768px) and (max-width: 1300px) {
    @content;
  }
}

@mixin content {
  @media screen and (min-width: 768px) and (max-width: 1200px) {
    @content;
  }
}

@mixin overContent {
  @media screen and (min-width: 1201px) {
    @content;
  }
}

@mixin hamburgerHeight {
  @media screen and (max-height: 800px) {
    @content;
  }
}

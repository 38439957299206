@use 'global/mixin' as m;
@use 'global/variable' as v;

.u-lh {
  &_2 {
    line-height: 2;
  }
  &_64px {
    @include m.pc {
      line-height: 64px;
    }
  }
}

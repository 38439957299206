@use 'global/mixin' as m;
@use 'global/variable' as v;

.p-faq {
  &_card {
    background-color: #fff;
    border-radius: 1rem;
    @include m.pc {
      padding: 13rem 0;
      border: solid 1px #ededed;
    }
  }
  &_background {
    padding: 2rem 0 3rem;
    background-color: #ebf2f8;
  }
  &_wrap {
    max-width: 840px;
    width: 100%;
    padding: 0 20px;
    margin: 0 auto;
    @include m.sp {
      padding: 0;
    }
  }
  &_right {
    @include m.sp {
      text-align: center;
    }
  }
  &_badge {
    color: #6dba44;
    padding: 0.1rem 0.5rem;
    border: 1px solid currentColor;
    font-size: 1.2rem;
    font-weight: 500;
    margin: 0 0 0 1rem;
    &--checkbox {
      position: absolute;
      top: 0;
      left: 66%;
      @include m.sp {
        top: -0.2rem;
        left: 80%;
      }
    }
  }
  &_box {
    display: block;
  }
  &_container {
    display: flex;
    align-items: center;
    @include m.sp {
      display: block;
    }
    &--start {
      align-items: flex-start;
    }
  }
  &_question {
    flex-basis: 25%;
  }
  &_answer {
    flex-basis: 75%;
    font-size: 1.6rem;
    @include m.sp {
      font-size: 1.2rem;
    }
  }
  &_case {
    margin: 2rem 0 0;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    @include m.sp {
      margin: 1rem 0 0;
      display: block;
    }
  }
  &_file {
    cursor: pointer;
    max-width: 22rem;
    width: 100%;
    border: 2px solid #6dba44;
    border-radius: 10rem;
    display: block;
    padding: 1.3rem;
    @include m.pc {
      transition: ease 0.3s transform;
      &:hover {
        transform: scale(1.05);
      }
    }
    @include m.sp {
      padding: 0.8rem;
    }
    &Container {
      display: flex;
      align-items: center;
      @include m.sp {
        margin: 1rem auto 0;
      }
    }
    &Input {
      display: none !important;
    }
    &Name {
      font-size: 1.6rem;
      margin: 1rem 0 0;
      @include m.sp {
        font-size: 1.2rem;
      }
    }
    &Text {
      font-size: 1.4rem;
      font-family: v.$zenMaruGothic;
      color: #6dba44;
      font-weight: 500;
      display: block;
      text-align: center;
      @include m.sp {
        font-size: 1.2rem;
      }
    }
  }
  &_textbox {
    display: block;
    background-color: #f6f6f6;
    padding: 1.5rem;
    border: 1px solid #8f8f8f;
    border-radius: 1rem;
    width: 100%;
    font-size: 1.6rem;
    &::placeholder {
      font-size: 1.6rem;
      @include m.sp {
        font-size: 1.2rem;
      }
    }
    @include m.sp {
      padding: 1rem;
      margin: 1rem 0 0;
    }
  }
  &_textarea {
    border: 1px solid #8f8f8f;
    border-radius: 1rem;
    background-color: #f6f6f6;
    height: 30rem;
    width: 100%;
    resize: vertical;
    font-size: 1.6rem;
    @include m.sp {
      margin: 1rem 0 0;
      font-size: 1.2rem;
    }
  }
  &_term {
    border: 1px solid #8f8f8f;
    border-radius: 1rem;
    padding: 3rem 2rem;
    height: 30rem;
    overflow-y: scroll;
    @include m.sp {
      padding: 1rem;
      height: 20rem;
    }
    &Text {
      font-size: 1.4rem;
      line-height: 2;
      @include m.sp {
        font-size: 1.2rem;
      }
    }
  }
  &_checkbox {
    display: block;
    text-align: center;
    cursor: pointer;
    &Text {
      font-size: 1.6rem;
      position: relative;
      padding: 0 0 0 5rem;
      @include m.sp {
        font-size: 1.2rem;
      }
      &::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        width: 3rem;
        height: 3rem;
        border: 1px solid #8f8f8f;
        background-color: #f6f6f6;
      }
      &::after {
        content: '';
        position: absolute;
        left: 4%;
        top: 50%;
        width: 1rem;
        height: 2rem;
        border: solid #6dba44;
        border-width: 0 3px 3px 0;
        transform: translateY(-50%) rotate(45deg);
        opacity: 0;
        @include m.sp {
          left: 5%;
        }
      }
    }
    &Container {
      // display: flex;
      // align-items: center;
      // justify-content: center;
      position: relative;
      margin: 5rem auto 0;
      @include m.sp {
        margin: 2.5rem auto 0;
      }
    }
  }
  &_radio {
    cursor: pointer;
    display: block;
    @include m.sp {
      & + & {
        margin: 1rem 0 0;
      }
    }
    &Text {
      position: relative;
      font-size: 1.4rem;
      font-weight: 500;
      display: inline-block;
      padding: 0 0 0 3rem;
      @include m.sp {
        font-size: 1.2rem;
      }
      &::before {
        content: '';
        display: block;
        position: absolute;
        transform: translateY(-50%);
        top: 50%;
        background-color: #f6f6f6;
        border: 0.1rem solid #8f8f8f;
        border-radius: 50%;
        width: 2rem;
        height: 2rem;
        left: 0rem;
      }
      &::after {
        content: '';
        display: block;
        position: absolute;
        transform: translateY(-50%);
        top: 50%;
        background-color: #6dba44;
        border-radius: 50%;
        opacity: 0;
        width: 1.6rem;
        height: 1.6rem;
        left: 0.2rem;
      }
    }
  }
  &_submit {
    cursor: pointer;
    display: block;
    max-width: 50rem;
    width: 100%;
    color: #fff;
    background-color: #6dba44;
    border: 2px solid transparent;
    font-size: 1.8rem;
    font-family: v.$zenMaruGothic;
    text-align: center;
    padding: 2.5rem;
    border-radius: 1rem;
    @include m.pc {
      transition: ease 0.3s transform;
      &:hover {
        transform: scale(1.05);
      }
    }
    @include m.sp {
      padding: 1.5rem;
      font-size: 1.4rem;
    }
    &--input {
      margin: 5rem auto 0;
      @include m.sp {
        margin: 3rem auto 0;
      }
    }
    &--small {
      max-width: 38rem;
      flex-basis: 47.5%;
    }
    &--reverse {
      color: #6dba44;
      background-color: #fff;
      border: 2px solid #6dba44;
    }
    &Container {
      display: flex;
      justify-content: space-between;
    }
  }
  &_button {
    margin: 8rem auto 0;
    @include m.sp {
      margin: 4rem auto 0;
    }
  }
}

.p-faq_radioInput:checked + .p-faq_radioText::after {
  opacity: 1;
}

.p-faq_checkboxInput:checked + .p-faq_checkboxText::after {
  opacity: 1;
}

@use 'global/mixin' as m;
@use 'global/variable' as v;

.c-logo {
  &--osoujihonpo {
    max-width: 21rem;
    width: 100%;
    @include m.sp {
      max-width: 14rem;
    }
  }
  &_corporate {
    &--header {
      max-width: 18rem;
      width: 100%;
      @include m.pc {
        transition: opacity 0.4s ease;
        &:hover {
          opacity: 0.7;
        }
      }
      @include m.header {
        max-width: 14rem;
      }
      @include m.tab {
        max-width: 12rem;
      }
      @include m.sp {
        max-width: 20vw;
        margin: 0 0 0.3rem 0;
      }
    }
    &--footer {
      max-width: 17rem;
      width: 100%;
      margin: 0 auto;
    }
  }
}

@use 'global/mixin' as m;
@use 'global/variable' as v;

.c-sentence {
  display: flex;
  align-items: center;
  &_contact {
    display: flex;
    align-items: baseline;
    margin: 0 0 0 2rem;
    @include m.sp {
      display: block;
      margin: 0 auto;
      max-width: 24rem;
      width: 100%;
    }
  }
  &_hamburger {
    margin: 0 0 0 2rem;
    flex: 1;
    @include m.wrap {
      margin: 0;
    }
    @include m.sp {
      margin: 0;
    }
  }
}

.c-breadcrumb {
  &_left {
    position: absolute;
    left: 105%;
    width: 100%;

    &--2 {
      top: 2rem;
    }

    &--11 {
      top: 11rem;
    }
    &--13 {
      top: 13rem;
    }
    &--15 {
      top: 15rem;
    }
    &--15p5 {
      top: 15.5rem;
    }
    &--19 {
      top: 19rem;
    }
    &--21 {
      top: 21rem;
    }
    &--22 {
      top: 22rem;
    }
  }

  &_single {
    margin-left: calc(20rem + 17%);
    margin-bottom: 2rem;
    padding-right: 20px;
  }

  &_right {
    position: absolute;
    top: 2rem;

    &--n13 {
      right: -13rem;
    }
    &--n16 {
      right: -16rem;
    }
    &--n23 {
      right: -23rem;
    }
    &--n29 {
      right: -29rem;
    }
  }
}

@use 'global/mixin' as m;
@use 'global/variable' as v;

.u-h {
  &_64 {
    @include m.pc {
      height: 64px;
    }
  }
}

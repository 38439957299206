@use 'global/mixin' as m;
@use 'global/variable' as v;

.c-hover {
  &_scale {
    @include m.pc {
      transition: transform 0.4s ease;
      &:hover {
        transform: scale(1.02);
      }
    }
  }
}

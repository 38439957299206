@use 'global/mixin' as m;
@use 'global/variable' as v;

.p-print {
  &About {
    padding: 12rem 0 0;
    @include m.sp {
      padding: 6rem 0 0;
    }
    &_frame {
      padding: 4rem 4rem 5rem 4rem;
      @include m.sp {
        padding: 4rem 2rem 3rem 2rem;
      }
    }
    &_gradient {
      padding: 12rem 0 0;
      background: linear-gradient(180deg, #fff 0%, #fff 4%, #e9f5e3 4%, #e9f5e3 90%, #fff 90%, #fff 100%);
      @include m.sp {
        padding: 6rem 0 0;
      }
    }
  }
  &Detail {
    padding: 12rem 0 16rem;
    @include m.sp {
      padding: 6rem 0;
    }
    &_item {
      max-width: 39rem;
      width: 100%;
    }
  }
  &Process {
    padding: 9rem 0 16rem;
    background-color: #e9f5e3;
    @include m.sp {
      padding: 6rem 0;
    }
    &_fill {
      padding: 8rem 10% 12rem;
      @include m.sp {
        padding: 2rem;
      }
    }
    &_title {
      background-color: #6dba44;
      padding: 0.2rem 1rem;
    }
    &_item {
      max-width: 36rem;
      width: 100%;
    }
  }
  &Clean {
    padding: 12rem 0 13rem;
    @include m.sp {
      padding: 6rem 0;
    }
    &_band {
      z-index: -1;
      left: calc(-100vw + 20px);
      top: 50%;
      transform: translateY(-50%);
      position: absolute;
      background-color: #ebf2f8;
      height: 7rem;
      width: 100vw;
      @include m.sp {
        height: 6rem;
      }
    }
    &_title {
      height: 7rem;
      max-width: 20rem;
      width: 100%;
      border-radius: 0rem 10rem 10rem 0rem;
      background-color: #ebf2f8;
      @include m.sp {
        max-width: 15rem;
        height: 6rem;
      }
    }
  }
  &_list {
    width: 100%;
    & + & {
      @include m.pc {
        border-left: solid 1px #6dba44;
        margin-left: 2rem;
        padding-left: 2rem;
      }
    }
  }
  &_right {
    margin: 0 0 0 5rem;
    @include m.sp {
      margin: 2rem 0 0;
    }
  }
  &_left {
    margin: 0 5rem 0 0;
    @include m.sp {
      margin: 2rem 0 0;
    }
  }
  &_balloon {
    position: absolute;
    top: -1.5rem;
    left: 7%;
    &--center {
      left: 50%;
      transform: translateX(-50%);
    }
  }
  &_number {
    z-index: 0;
    position: absolute;
    top: -6rem;
    left: 3rem;
    @include m.sp {
      top: -2rem;
      left: 3rem;
    }
  }
  &_table {
    width: 100%;
  }
  &_head {
    background-color: #6dba44;
    text-align: center;
    &--80 {
      flex-basis: 8%;
    }
    &--85 {
      flex-basis: 8.5%;
    }
    &--180 {
      flex-basis: 18%;
    }
    &--190 {
      flex-basis: 19%;
    }
    &--210 {
      flex-basis: 21%;
    }
    &--270 {
      flex-basis: 27%;
    }
    & + & {
      border-left: solid 1px #bfbfbf;
    }
  }
}

@use 'global/mixin' as m;
@use 'global/variable' as v;

.c-scroll {
  display: block;
  position: relative;
  &_line {
    position: absolute;
    top: 2rem;
    left: 0;
    width: 5.5rem;
    height: 0.3rem;
    background-color: #6dba44;
    animation: arrowmove 1s ease-in-out infinite;
    border-radius: 1rem;
    &::after {
      content: '';
      position: absolute;
      bottom: -0.1rem;
      right: 0.3rem;
      width: 0.3rem;
      height: 1.2rem;
      background: #6dba44;
      transform: rotate(135deg);
      border-radius: 1rem;
    }
  }
}

@keyframes arrowmove {
  0% {
    left: 0rem;
  }
  50% {
    left: 1rem;
  }
  100% {
    left: 0rem;
  }
}

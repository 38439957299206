@use 'global/mixin' as m;
@use 'global/variable' as v;

.p-corp {
  padding: 12rem 0 38rem;
  position: relative;
  @include m.content {
    padding: 12rem 0 27vw;
  }
  @include m.sp {
    padding: 6rem 0;
  }
  &_image {
    max-width: 74rem;
    width: 100%;
    position: absolute;
    left: 0;
    top: 12rem;
    @include m.nearContent {
      width: 54%;
    }
    @include m.wrap {
      width: 45%;
    }
    @include m.sp {
      margin: 2rem 0 0;
      position: initial;
      & > img {
        border-radius: 1rem;
      }
    }
  }
  &_sentence {
    max-width: 39rem;
    width: 100%;
    margin-left: auto;
    @include m.wrap {
      max-width: 35rem;
    }
  }
  &_band {
    height: 40rem;
    background-color: #f0f5fa;
    border-top-left-radius: 1rem;
    border-bottom-left-radius: 1rem;
    position: absolute;
    z-index: -1;
    width: 86%;
    right: 0;
    top: 4rem;
    @include m.sp {
      width: 100%;
    }
  }
}

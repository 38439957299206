@use 'global/mixin' as m;
@use 'global/variable' as v;

.c-text {
  font-size: 1.6rem;
  font-family: v.$yuGothic;
  line-height: calc(29 / 16);
  @include m.sp {
    font-size: 1.2rem;
  }
  &--white {
    color: #fff;
  }
  &--royalblue {
    color: #005bac;
  }
  &--green {
    color: #6dba44;
  }
  &--xs {
    font-size: 1.2rem;
    @include m.sp {
      font-size: 1.2rem;
    }
  }
  &--small {
    font-size: 1.4rem;
    @include m.sp {
      font-size: 1.2rem;
    }
  }
  &--large {
    font-size: 1.8rem;
    @include m.sp {
      font-size: 1.4rem;
    }
  }
  &--wide {
    letter-spacing: 0.06em;
  }
}

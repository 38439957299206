@use 'global/mixin' as m;
@use 'global/variable' as v;

.c-number {
  position: absolute;
  top: -4rem;
  width: 100%;
  left: 1.6rem;
  @include m.sp {
    top: -3rem;
  }
  &--work {
    left: 0;
    top: -2rem;
    @include m.sp {
      top: -1rem;
    }
  }
  &--right {
    left: auto;
    right: 0;
    top: -2rem;
    @include m.sp {
      top: -1rem;
    }
  }
  &_01 {
    width: 100%;
    max-width: 6.8rem;
    @include m.sp {
      max-width: 4.9rem;
    }

    &--small {
      max-width: 5.5rem;
      @include m.sp {
        max-width: 4rem;
      }
    }
  }
  &_02 {
    width: 100%;
    max-width: 8.1rem;
    @include m.sp {
      max-width: 5.6rem;
    }

    &--small {
      max-width: 6.4rem;
      @include m.sp {
        max-width: 5rem;
      }
    }
  }
  &_03 {
    width: 100%;
    max-width: 8.2rem;
    @include m.sp {
      max-width: 5.6rem;
    }

    &--small {
      max-width: 6.6rem;
      @include m.sp {
        max-width: 4.2rem;
      }
    }
  }
}

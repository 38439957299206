@keyframes rotate {
  0% {
    transform: rotate(0deg) translate3d(0, 0, 0);
  }
  25% {
    transform: rotate(3deg) translate3d(0, 0, 0);
  }
  50% {
    transform: rotate(-3deg) translate3d(0, 0, 0);
  }
  75% {
    transform: rotate(1deg) translate3d(0, 0, 0);
  }
  100% {
    transform: rotate(0deg) translate3d(0, 0, 0);
  }
}
@keyframes rotateAbsoluteX {
  0% {
    transform: translate3d(-50%, 0, 0) translateZ(0);
  }
  25% {
    transform: translate3d(4px, 0, 0) translateZ(0);
  }
  50% {
    transform: translate3d(calc(-50% + -3px), 0, 0) translateZ(0);
  }
  75% {
    transform: translate3d(calc(-50% + 2px), 0, 0) translateZ(0);
  }
  100% {
    transform: translate3d(-50%, 0, 0) translateZ(0);
  }
}

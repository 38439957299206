@use 'global/mixin' as m;
@use 'global/variable' as v;

@for $i from 0 through 20 {
  .u-pb_#{$i} {
    padding-bottom: #{$i}rem;
    @include m.sp {
      padding-bottom: calc(#{$i}rem/ 2);
    }
  }
}

@use 'global/mixin' as m;
@use 'global/variable' as v;

.c-swiper {
  // max-width: 1240px;
  width: 100%;
  margin: 0 auto;
  // padding: 0 20px;
  @include m.sp {
    // width: 90%;
    margin: 0 auto;
    padding: 0;
  }

  &_inner {
    @include m.sp {
      padding: 0 0 10rem !important;
    }
  }
}

.swiper-slide {
  @include m.pc {
    padding: 0 20px;
  }
}

@use 'global/mixin' as m;
@use 'global/variable' as v;

.c-balloon {
  max-width: 15rem;
  width: 100%;
  color: #fff;
  background-color: #6dba44;
  text-align: center;
  padding: 0.6rem;
  position: relative;
  @include m.sp {
    max-width: 12rem;
  }
  &::before {
    content: '';
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: -0.6rem;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0.8rem 0.5rem 0 0.5rem;
    border-color: #6dba44 transparent transparent transparent;
  }
  &--oval {
    border-radius: 10rem;
  }
  &--darkgreen {
    background-color: #3a9e80;
    &::before {
      border-color: #3a9e80 transparent transparent transparent;
    }
  }
  &--large {
    max-width: 36rem;
    width: 100%;
    padding: 1.6rem;
    &::before {
      bottom: -1.2rem;
      border-width: 1rem 1rem 0 1rem;
    }
  }
  &--130 {
    max-width: 13rem;
  }
  &--180 {
    max-width: 18rem;
  }
  &--220 {
    max-width: 22rem;
  }
  &--360 {
    max-width: 36rem;
  }
}

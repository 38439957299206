@use 'global/mixin' as m;
@use 'global/variable' as v;
@use 'global/keyframes' as k;

.p-top {
  &Print {
    background-color: #e9f5e3;
    padding: 15rem 0 17rem;
    @include m.sp {
      padding: 6rem 0;
    }
    &_container {
      margin: 8rem 0 0 0;
      justify-content: space-between;
    }
    &_button {
      margin: 5rem auto 0;
      @include m.sp {
        margin: 2.5rem auto 0;
        width: 90%;
      }
    }
  }
  &Effort {
    padding: 11rem 0 12rem;
    background: url('../img/top_background_01.png') no-repeat center;
    background-size: cover;
    position: relative;
    @include m.sp {
      padding: 6rem 0;
    }
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba($color: #6dba44, $alpha: 0.85);
    }
    &_image {
      max-width: 22rem;
      width: 100%;
      @include m.sp {
        max-width: 12rem;
        display: flex;
        align-items: center;
      }
    }
    &_box {
      width: 100%;
      @include m.sp {
        & + & {
          margin: 2rem 0 0 0;
        }
      }
    }
    &_caption {
      margin: 0 0 0 3rem;
      max-width: 22rem;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      @include m.sp {
        margin: 0 0 0 1.5rem;
      }
    }
  }
  &Clean {
    padding: 17rem 0;
    @include m.sp {
      padding: 6rem 0;
    }
    &_inner {
      max-width: 1000px;
      width: 100%;
      margin: 0 auto;
      box-sizing: border-box;
      display: flex;
      align-items: flex-start;
      @include m.sp {
        display: block;
        margin: 0 auto;
        padding: 0;
      }
    }
    &_oval {
      background-color: #fff;
      border-top-right-radius: 10rem;
      border-bottom-right-radius: 10rem;
      padding: 1rem 1rem 1rem 0;
      margin: 0 0 0 -7rem;
      width: 31rem;
      @include m.sp {
        padding: 0.5rem 0.5rem 0.5rem 0;
        margin: 0 0 0 -3.5rem;
        width: auto;
      }
    }
    &_image {
      max-width: 55rem;
      width: 100%;
      margin: 9rem 0 0 -10rem;
      @include m.sp {
        width: 90%;
        margin: -1rem 0 0 auto;
      }
    }
    &_card {
      padding: 6% 15rem 8% 7rem;
      max-width: 55rem;
      width: 100%;
      background-color: #005bac;
      border-radius: 1rem;
      @include m.sp {
        width: 90%;
        padding: 6% 3.5rem 8% 3.5rem;
      }
    }
  }
  &News {
    &_background {
      background-color: #e9f5e3;
      padding: 7rem 0 9rem;
      position: relative;
      @include m.sp {
        padding: 6rem 0;
      }
    }
    &_button {
      margin: -3rem auto 0;
      @include m.sp {
        width: 90%;
      }
    }
  }
  &About {
    padding: 18rem 0 22rem;
    position: relative;
    @include m.sp {
      padding: 6rem 0;
    }
  }
  &Recruit {
    &_image {
      cursor: pointer;
      max-width: 90rem;
      width: 100%;
      margin: 0 auto -3rem;
      display: block;
      position: relative;
      z-index: 1;
      @include m.pc {
        transition: transform 0.4s ease;
        &:hover {
          transform: scale(1.02);
        }
      }
    }
  }
  &Contact {
    background: url('../img/top_background_02.png') no-repeat center;
    background-size: auto;
    position: relative;
    padding: 13rem 0;
    @include m.sp {
      padding: 6rem 0;
    }
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba($color: #f2f2f2, $alpha: 0.85);
    }
    &_card {
      display: flex;
      padding: 1rem;
      align-items: center;
      background-color: #fff;
      border-radius: 1rem;
      max-width: 90rem;
      width: 100%;
      margin: 5rem auto 0;
      @include m.sp {
        margin: 2rem auto 0;
        display: block;
      }
    }
  }
}

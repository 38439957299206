@use 'global/mixin' as m;
@use 'global/variable' as v;

.p-info {
  padding: 11rem 0 22rem;
  @include m.sp {
    padding: 6rem 0 9rem;
  }
  &_button {
    font-size: 1.4rem;
    max-width: 22rem;
    width: 100%;
    padding: 0.8rem;
    @include m.sp {
      margin: 2rem auto 0;
    }
    &--larger {
      max-width: 23rem;
      text-align: left;
      padding: 0.8rem 0.8rem 0.8rem 1.6rem;
      margin: 3.5rem 0 0;
      @include m.sp {
        margin: 1.5rem 0 0;
      }
    }
  }
  &_card {
    flex-basis: 30%;
    background-color: #fff;
    margin: -4rem 0 0;
    padding: 3.5rem 3rem;
    border-radius: 0.5rem;
    position: relative;
    &::before {
      content: '';
      position: absolute;
      width: 7rem;
      height: 8px;
      background-color: #6dba44;
      top: 0;
      left: 3rem;
      @include m.sp {
        left: 2rem;
      }
    }
    @include m.sp {
      padding: 2rem;
      & + & {
        margin: 2rem auto 0;
      }
    }
  }
  &_background {
    background-size: cover;
    background-position: center center;
    width: 100%;
    height: 350px;
    background-image: url('../img/info_background_01.png');
    @include m.sp {
      padding: 2.5rem 0;
      height: 100%;
    }
  }
}

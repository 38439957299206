@use 'global/mixin' as m;
@use 'global/variable' as v;

.p-eco {
  &About {
    padding: 17rem 0 12rem;
    background-color: #e9f5e3;
    @include m.sp {
      padding: 6rem 0;
    }
    &_title {
      background-color: #6dba44;
      padding: 0.2rem 1rem;
    }
  }
  &Action {
    padding: 12rem 0 0;
    @include m.sp {
      padding: 6rem 0;
    }
  }
  &Policy {
    background-color: #f5f5f5;
    padding: 25rem 0 22rem;
    @include m.sp {
      padding: 12rem 0 6rem;
    }
  }
  &_frame {
    border: 2px solid #6dba44;
    border-radius: 1rem;
    background-color: #fff;
    padding: 5rem 6% 5rem 7%;
    margin-bottom: -13rem;
    @include m.sp {
      padding: 4rem 2rem 2rem 2rem;
    }
  }
  &_balloon {
    position: absolute;
    top: -1.5rem;
    left: 7%;
  }
  &_list {
    position: relative;
    padding: 0 0 0 0.5em;
    &::before {
      content: '';
      display: block;
      position: absolute;
      width: 1em;
      height: 1em;
      left: -1em;
      top: 0.5em;
      border-radius: 50%;
      background-color: #6dba44;
    }
  }
  &_item {
    &:nth-child(2) {
      margin: 17rem auto 0;
      @include m.nearContent {
        margin: 9rem auto 0;
      }
      @include m.content {
        margin: 5rem auto 0;
      }
      @include m.sp {
        margin: 5rem auto 0;
      }
    }
    &:nth-child(3) {
      margin: 29rem auto 0;
      @include m.nearContent {
        margin: 18rem auto 0;
      }
      @include m.content {
        margin: 5rem auto 0;
      }
      @include m.sp {
        margin: 5rem auto 0;
      }
    }
  }
}

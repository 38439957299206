@use 'global/mixin' as m;
@use 'global/variable' as v;

.c-maru {
  font-size: 1.6rem;
  font-family: v.$zenMaruGothic;
  color: #005bac;
  letter-spacing: 0.06em;
  @include m.sp {
    font-size: 1.2rem;
  }
  &--white {
    color: #fff;
  }
  &--black {
    color: #000;
  }
  &--green {
    color: #6dba44;
  }
  &--lightgreen {
    color: #e9f5e3;
  }
  &--darkgreen {
    color: #3a9e80;
  }
  &--mintcream {
    color: #ebf5f2;
  }
  &--xsmall {
    font-size: 1.2rem;
  }
  &--small {
    font-size: 1.4rem;
    @include m.sp {
      font-size: 1.2rem;
    }
  }
  &--large {
    font-size: 1.8rem;
    @include m.sp {
      font-size: 1.4rem;
    }
  }
  &--20 {
    font-size: 2rem;
    @include m.sp {
      font-size: 1.6rem;
    }
  }
  &--22 {
    font-size: 2.2rem;
    @include m.sp {
      font-size: 1.6rem;
    }
  }
  &--24 {
    font-size: 2.4rem;
    @include m.sp {
      font-size: 1.6rem;
    }
  }
  &--28 {
    font-size: 2.8rem;
    @include m.sp {
      font-size: 1.8rem;
    }
  }
  &--38 {
    font-size: 3.8rem;
    @include m.sp {
      font-size: 1.9rem;
    }
  }
  &--40 {
    font-size: 4rem;
    @include m.sp {
      font-size: 2rem;
    }
  }
  &--46 {
    font-size: 4.6rem;
    @include m.sp {
      font-size: 2.3rem;
    }
  }
  &--54 {
    font-size: 5.4rem;
    @include m.sp {
      font-size: 2.7rem;
    }
  }
  &--64 {
    font-size: 6.4rem;
    @include m.sp {
      font-size: 3.2rem;
    }
  }
  &--100 {
    font-size: 10rem;
    @include m.sp {
      font-size: 5rem;
    }
  }
}
